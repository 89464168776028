import axios from 'axios';
import { UserData } from '../store/user.store';

class GlobalServis {
  GlobalServis(Type, URL, DemandData) {
    let ServiceURL = null;
    if (process.env.NODE_ENV === 'production') {
      ServiceURL = 'https://bilgisistemiapi.gelsinteknik.com/api/'
    }
    else {
      ServiceURL = 'https://localhost:44386/api/'
    }
    return axios({
      method: Type.toUpperCase(),
      data: DemandData,
      headers: {
        'api-version': '1.0',
        contentType: 'application/x-www-form-urlencoded',
        Authorization: 'bearer ' + UserData.getters.getuserData,
      },
      url: ServiceURL + URL,
    }).then((response) => {
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(() => {
          this.$router.push({ path: "/Anasayfa" });
        },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.$toast.add({
              severity: "warn",
              summary: "Çıkış Hatası",
              detail: this.message,
              life: 3000,
            });
          }
        );
      }
      else {
        return response;
      }
    }).catch(function (error) {
      return error;
    })
  }
}

export default new GlobalServis();

