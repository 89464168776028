import { createWebHistory, createRouter } from "vue-router";
import AdminLayout from "./components/Layouts/AdminLayout.vue";
import PublicLayout from "./components/Layouts/PublicLayout.vue";
import AuthLayout from "./components/Layouts/AuthLayout.vue";


const routes = [
    {
        path: "",
        redirect: "/Auth/Login"
    },
    {
        path: "/Anasayfa",
        beforeEnter(to, from, next) {
            window.location.replace("https://bilgisistemi.gelsinteknik.com")
        }
    },
    {
        path: "/ExcelIndir/Template1",
        beforeEnter(to, from, next) {
            window.location.replace("https://www.bilgisistemi.gelsinteknik.com/ExcelTemplate/Template1.xlsx")
        }
    },
    // {
    //     path: "/",
    //     name: "",
    //     component: PublicLayout,
    //     children: [{
    //         path: "/MainPage",
    //         name: "MainPage",
    //         component: () =>
    //             import("./components/PublicPage/MainPage.vue")
    //     }]
    // },

    { path: "/Auth", redirect: "/Auth/Login" },
    {
        path: "/Auth",
        name: "",
        component: AuthLayout,
        children: [{
            path: "/Auth/Login",
            name: "Login",
            component: () =>
                import("./components/PublicPage/Auth/Login.vue"),
        },
        {
            path: "/Auth/Signup",
            name: "Signup",
            component: () =>
                import("./components/PublicPage/Auth/Signup.vue")
        }
        ]
    },
    { path: "/Muhasebe", redirect: "/Muhasebe/Bildirimler" },
    {
        path: "/Muhasebe",
        name: "",
        component: AdminLayout,
        children: [
            {
                path: "/Muhasebe/BildirimYukle",
                name: "BildirimYukle",
                component: () =>
                    import("./components/PrivatePage/Muhasebe/BildirimYukle.vue")
            },
            {
                path: "/Muhasebe/BildirimGonder",
                name: "BildirimGonder",
                component: () =>
                    import("./components/PrivatePage/Muhasebe/BildirimGonder.vue")
            },
            {
                path: "/Muhasebe/ExcelIndir",
                name: "ExcelIndir",
                component: () =>
                    import("./components/PrivatePage/Muhasebe/ExcelIndir.vue")
            },
            {
                path: "/Muhasebe/Mukellef",
                name: "Mukellef",
                component: () =>
                    import("./components/PrivatePage/Muhasebe/Mukellef.vue")
            }, {
                path: "/Muhasebe/OdemeGrup",
                name: "OdemeGrup",
                component: () =>
                    import("./components/PrivatePage/Muhasebe/OdemeGrup.vue")
            },
        ]
    },
    { path: "/Ileti", redirect: "/Ileti/MailGonder" },
    {
        path: "/Ileti/MailGonder",
        name: "",
        component: AdminLayout,
        children: [
            {
                path: "/Ileti/MailGonder",
                name: "MailGonder",
                component: () =>
                    import("./components/PrivatePage/Ileti/MailGonder.vue")
            },
            {
                path: "/Ileti/SmsGonder",
                name: "SmsGonder",
                component: () =>
                    import("./components/PrivatePage/Ileti/SmsGonder.vue")
            },
        ]
    },
    { path: "/Admin", redirect: "/Admin/Main" },
    {
        path: "/Admin",
        name: "",
        component: AdminLayout,
        children: [{
            path: "/Admin/Main",
            name: "Main",
            component: () =>
                import("./components/PrivatePage/AdminSayfalari/AdminSayfasi.vue")
        },
        ]
    },
    { path: "/Yonetim", redirect: "/Yonetim/Rol/Rol" },
    {
        path: "/Yonetim",
        name: "",
        component: AdminLayout,
        children: [
            {
                path: "/Yonetim/Kullanicilar",
                name: "Kullanicilar",
                component: () =>
                    import("./components/PrivatePage/Yönetim/KullaniciSayfalari/KullanicilarSayfasi.vue")
            },
            {
                path: "/Yonetim/Sirketler",
                name: "Sirketler",
                component: () =>
                    import("./components/PrivatePage/Yönetim/KullaniciSayfalari/SirketSayfasi.vue")
            },
            {
                path: "/Yonetim/Rol/Rol",
                name: "Rol",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Rol/Rol.vue")
            },
            {
                path: "/Yonetim/RolYetki",
                name: "RolYetki",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Rol/RolYetki.vue")
            },
            {
                path: "/Yonetim/Yetki",
                name: "Yetki",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Yetki/Yetki.vue")
            },
            {
                path: "/Yonetim/WebSayfa",
                name: "WebSayfa",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Yetki/WebSayfa.vue")
            },
            {
                path: "/Yonetim/Sistem/TabloBoyut",
                name: "TabloBoyut",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Sistem/TabloBoyut.vue")
            },
            {
                path: "/Yonetim/SmsSablon/SmsSablon",
                name: "SmsSablon",
                component: () =>
                    import("./components/PrivatePage/Yönetim/SmsSablon/SmsSablon.vue")
            },

        ]
    },
   
    {
        path: "/Bildirim/:donem/:vk/:ps", //donem, vkn, psirket
        name: "Bildirim",
        component: PublicLayout,
        children: [{
            path: "/Bildirim",
            name: "Bildirim",
            component: () =>
                import("./components/PublicPage/Bildirim/Bildirim.vue")
        }]
    },
    // {
    //     path: "/:catchAll(.*)",
    //     component: () =>
    //         import("./components/PublicPage/ErrorPages/NotFound.vue"),
    // },
    {
        path: "/NotFound",
        component: () =>
            import("./components/PublicPage/ErrorPages/NotFound.vue"),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

import GlobalServis from '../src/services/GlobalServis';
import index from './store/index';

router.beforeEach((to, from, next) => {
    if (
        to.href == '/Anasayfa'      || to.href == "/ExcelIndir/Template1" ||
        to.href == "/Auth/Login"    ||
        to.href == "/NotFound"      ||
        to.href.match(/Bildirim*/)
    ) {
        next();
    } else {
        let data = {
            URL: to.href
        }
        GlobalServis.GlobalServis("POST", "GetYetkiKontrol", data).then(
            (res) => {
                if (res.status == 200 && res.data == true) {
                    next();
                } else {
                    if (index.state.auth.status.loggedIn) {
                        next('/NotFound');
                    } else {
                        next('');
                    }
                }
            }
        );
    }
})

router.afterEach(() => {
})

export default router;