<template>
  <div class="body">
    <div>
      <transition name="fade" mode="out-in">
        <ScrollPanel style="width: 100%;" :style="{ 'height': windowHeight + 'px' }">
          <Menubar :model="items">
            <template #start>
              <img alt="logo" :src="require(`@/assets/logo/logo1.png`)" height="30" class="p-mr-2" />
            </template>
            <template #end>

            </template>
          </Menubar>
          <router-view />

          <!-- <div v-if="SmsMailHakBarShow" class="bottom-sitcky-bar p-d-flex p-jc-center">
            <div class="p-mt-1">
              <ProgressSpinner style="width:3vw;height:3vh;" strokeWidth="10" fill="#ff41203a" animationDuration=".9s"/>&nbsp;&nbsp;
               {{ SmsMailHakBarItems[0].tur }} gönderme hakkınız bilmiştir. İşlemlere devam etmek için &nbsp;
              <router-link to="/Yonetim/Odeme/OdemePaket"> buradan </router-link>&nbsp; satınalınız.
            </div>
          </div> -->

        </ScrollPanel>
      </transition>
    </div>
  </div>
</template>

<script>

import GlobalServis from "../../services/GlobalServis";
export default {
  data() {
    return {
      windowHeight: window.innerHeight,
      tempitems: [],
      items: [],

    }
  },
  methods: {
    initServis() {
      this._GetNavbar();
    },
    Logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ path: "/Anasayfa" });
      },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$toast.add({
            severity: "warn",
            summary: "Çıkış Hatası",
            detail: this.message,
            life: 3000,
          });
        }
      );
    },
    _GetNavbar() {
      GlobalServis.GlobalServis("GET", "GetNavbar", "").then(
        (res) => {
          if (res.status == 200) {
            this._tempitems = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      ).then(() => {
        let temp_ = []
        let counter = 0
        this._tempitems.forEach((value) => {
          if (value.UstMenu == 0) {
            temp_.push({
              label: value.Tanim,
              to: value.Url,
              icon: "pi " + value.IconAd
            })
            let counter2 = 0;
            let _tempSubMenu = []
            this._tempitems.forEach(value2 => {
              if (value.YetkiYeri_rowid == value2.UstMenu) {
                _tempSubMenu.push({
                  label: value2.Tanim,
                  to: value2.Url,
                  icon: "pi " + value2.IconAd,
                })
                temp_[counter].items = _tempSubMenu
                if (value2.Seperator === true) {
                  _tempSubMenu.push({
                    separator: true
                  })
                }
                counter2++;
                temp_[counter].items = _tempSubMenu
              }
            })
            counter++;
          }
        })
        temp_.push({
          label: "Quit",
          // to: "/Cikis",
          command: () => this.Logout(),
          icon: "pi  pi-power-off"
        })
        this.items = temp_

      })
    },
  
    onResize() {
      this.windowHeight = window.innerHeight
    },
    // created() {
    //   this.interval = setInterval(() => this._GetSmsMailHakKontrol(), 10000);
    // },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.initServis();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.body {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: var(--gray-300);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.bottom-sitcky-bar {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: normal;

  border-radius: 25px 25px 0 0;
  height: 5vh;
  background-color: #ff412060;
  position: fixed;
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
  box-shadow: 5px 10px 8px 10px hwb(0 73% 4%);
}
</style>