import axios from 'axios';
import { UserData } from '../store/user.store';
import GlobalServis from './GlobalServis';
//  const API_URL = 'https://localhost:44386/api/';
//  const API_URL = 'https://api.gelsinteknik.com/api/';

class AuthService {
  login(user) {
    let data = 'grant_type=password&username=' + user.username + '&password=' + user.password
    return axios({
      method: 'POST',
      url: this.checkURL() + 'login',
      //url: 'https://localhost:44386/api/' + 'login',
      headers: {
        withCredentials: true,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: (data)
    }).then((response) => {
      if (response.status === 200) {
        UserData.dispatch('setaccessToken', response.data.access_token);
      }
      else {
        //TODO:  login failed alert yapılacak 
      }
      return response.data;
    })
  }

  logout() {
    GlobalServis.GlobalServis("GET", "logout", "").then((res) => {
      if (res.status == 200) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
      } else if (res.status == 400) {
        this.$toast.add({
          severity: "error",
          summary: "Hata",
          detail: "Önemli bir hata meydana geldi",
          life: 3000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Hata",
          detail: "Bilinmeyen bir hata meydana geldi",
          life: 3000,
        });
      }
    });
  }

  checkURL() {
    let TokenURL = null;
    if (process.env.NODE_ENV === 'production') {

      TokenURL = 'https://bilgisistemiapi.gelsinteknik.com/api/'
    }
    else {
      TokenURL = 'https://localhost:44386/api/'
    }
    return TokenURL;
  }

  // register(user) {
  //   return axios.post(API_URL + 'signup', {
  //     username: user.username,
  //     email: user.email,
  //     password: user.password
  //   });
  // }
}

export default new AuthService();